import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/Clients/StephaneCatoire/Gastby/scatoire-perso/src/components/blog-post-layout.jsx";
import { graphql } from "gatsby";
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dear all,`}</p>
    <p>{`It is with a great pleasure that I announce the creation of my new personal website!`}</p>
    <p>{`I must admit I tought it would be simpler to create a personal website online. After all, a simple expressjs site, and voilà! The world would be aware of my existance !`}</p>
    <h2>{`Where to host ?`}</h2>
    <p>{`Actually, even if developping the site itself was not problematic, hosting it (on the cheap) started to create troubles. I looked at OVH prices for a website, and the problem was that it could not host nodejs sites (at least for a good price). Wordpress, drupal whatever, but no nodejs.`}</p>
    <p>{`OK, no problem, I have the AWS free tier, so, it should not be a problem, right ?`}</p>
    <p>{`So I looked first to EC2 instances. Price ok but maybe overkill for a simple site. After all, It seems that the easier and cheaper way to go is to create a static site and have it hosted on Amazon S3.`}</p>
    <h2>{`Hexo`}</h2>
    <p>{`So I looked on different ways to create a static website. Hugo seemed popular, but I did not want to dable in a new language, so I looked on node based site, and I found HEXO, who looked simple enough. The advantage is that it includes a blog mechanism, and it is always good to update your website with new content, so google is happy and move you site in its rankings.`}</p>
    <p>{`So all good, I was able to create a nice website in hexo, not so waw, but OK nevertheless.`}</p>
    <p>{`Unfortunately, after having it hosted on Amazon S3, I had a little problem. The top links were made on the root folder, which is normally not a problem since your server would automatically look for "index.html" if it did not found it. On S3, no way (unless going through Cloudflare, but I wanted first to see if I could bring my site online).`}</p>
    <h2>{`GatsbyJS`}</h2>
    <p>{`So let's try something else. GatsbyJS is also a static site generator, but with an emphasis on React for performance. I did not dabbled in react before, so that was a good opportunity to play with it.`}</p>
    <p>{`Gatsby has its own quirks, especially with image and its internal graphQL system, that I will detail later. So I played with it, and finaly came with this site.`}</p>
    <h2>{`Finally`}</h2>
    <p>{`I will post later a more detailed story of all the steps that were needed before attaining this result.`}</p>
    <p>{`Stay tuned !`}</p>
    {
      /* Footer to allow translation to be copied on each post
          Boring but wainting for a better solution */
    }



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      